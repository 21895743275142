import { Outlet, NavLink, Link } from "react-router-dom";

import handytec from "../../assets/handytec_logo_index_white.svg";

import styles from "./Layout.module.css";

import { useLogin } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    {/*
                    <Link to="/" className={styles.headerTitleContainer}>
                        <img
                            src={handytec}
                            alt="handytec logo"
                            aria-label="Enlace al Sitio web de handytec"
                            width="180px"
                            height="40px"
                            className={styles.handytecLogo}
                        />
                    </Link>
                    <nav></nav>
                    */}
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
